import React, { useContext, Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"

import { AppContext } from "../../../context/AppContext"
import FilePreview from "../../Upload/FilePreview"

import styles from "../utils/cart.module.scss"

const DocumentsCardRow = ({
  fileName,
  oldFileName,
  handleDeleteDocument,
  index,
  file,
  isFullSize,
}) => {
  const data = useStaticQuery(graphql`
    {
      pdfThumbnail: file(relativePath: { eq: "pdf.png" }) {
        childImageSharp {
          fixed(width: 48) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const pdfThumbnail = data.pdfThumbnail.childImageSharp.fixed

  const { dispatch } = useContext(AppContext)
  let oldFileNameArray = oldFileName.split(".")
  let fileType = oldFileNameArray[oldFileNameArray.length - 1] || "file"

  const imageThumbnail =
    fileType === "pdf" ? (
      <Img fixed={pdfThumbnail} />
    ) : (
      <img
        src={file.path}
        className={classNames("image", styles["documentRow__imgThumbnail"], {
          [styles["documentRow__imgThumbnail__isFullsize"]]: isFullSize,
          "mb-2": isFullSize,
        })}
        alt="attachment"
      />
    )

  const handleFileClick = () =>
    dispatch({
      type: "SHOW_MODAL",
      payload: {
        content: <FilePreview file={file} isPdf={fileType === "pdf"} />,
      },
    })

  return (
    <article className={classNames("media")}>
      <figure
        onClick={handleFileClick}
        className={classNames("media-left", {
          "media-content": isFullSize,
        })}
      >
        {imageThumbnail}
      </figure>
      {!isFullSize && (
        <Fragment>
          <div onClick={handleFileClick} className="media-content is-size-7">
            <div className="subtitle">
              {fileName}
              <p className="help">{oldFileName}</p>
            </div>
          </div>
        </Fragment>
      )}
      <div className="media-right">
        <a
          className="delete"
          onClick={() => handleDeleteDocument(index)}
          type="button"
        />
      </div>
    </article>
  )
}

export default DocumentsCardRow
