import React, { useContext } from "react"
import classNames from "classnames"

import Card from "../Elements/Card/Card"
import MedicinesCardRow from "./MedicinesCard/MedicinesCardRow"

import { AppContext } from "../../context/AppContext"
import { accumulatePrice } from "../Epharmacy/services/computations"
import styles from "./utils/cart.module.scss"
import CollapsibleCard from "../Elements/CollapsibleCard"

const MedicinesCard = ({ open }) => {
  const { dispatch, state } = useContext(AppContext)
  const { epharmacy } = state
  const { medicines } = epharmacy

  const handleMedicineQtyChange = (event, index) => {
    const medicineList = [...medicines]
    if (medicineList[index].molecule) {
      const { value } = event.target
      if (value.includes("e") || value.includes("-")) return
      medicineList[index].qty = value
      dispatch({ type: "SAVE_MEDICINES", payload: medicineList })
    }
  }

  const handleDeleteMedicine = index => {
    const medicineList = [...medicines]
    medicineList.splice(index, 1)
    dispatch({ type: "SAVE_MEDICINES", payload: medicineList })
  }

  return (
    <CollapsibleCard
      title={`Medicines Ordered (${medicines.length})`}
      color="white"
      errorMessage={
        medicines?.length === 0 &&
        "No medicines added.<br/>Please search to add medicines."
      }
      isOpen={open}
    >
      <div>
        <ol className={classNames(styles["cart"], "has-text-left")}>
          {medicines.map((medicine, index) => (
            <MedicinesCardRow
              medicine={medicine}
              index={index}
              handleDeleteMedicine={handleDeleteMedicine}
              handleMedicineQtyChange={handleMedicineQtyChange}
            />
          ))}
        </ol>
        <hr className="has-background-light" />
        <div className="columns is-size-5 is-mobile">
          <div className="column has-text-right">Total:</div>
          <div className="column is-narrow has-text-weight-bold">
            <span className="mr-1">
              Php {accumulatePrice(medicines).toFixed(2)}
            </span>
          </div>
        </div>
      </div>
    </CollapsibleCard>
    // <Card
    //   header={`Medicines Ordered (${medicines.length})`}
    //   errorMessage={
    //     medicines.length === 0 &&
    //     "No medicines added.<br/>Please search to add medicines."
    //   }
    // >
    //   <ol className={classNames(styles["cart"], "has-text-left")}>
    //     {medicines.map((medicine, index) => (
    //       <MedicinesCardRow
    //         medicine={medicine}
    //         index={index}
    //         handleDeleteMedicine={handleDeleteMedicine}
    //         handleMedicineQtyChange={handleMedicineQtyChange}
    //       />
    //     ))}
    //   </ol>
    //   <hr className="has-background-light" />
    //   <div className="columns is-size-5 is-mobile">
    //     <div className="column has-text-right">Total:</div>
    //     <div className="column is-narrow has-text-weight-bold">
    //       <span className="mr-1">
    //         Php {accumulatePrice(medicines).toFixed(2)}
    //       </span>
    //     </div>
    //   </div>
    // </Card>
  )
}

export default MedicinesCard
