import React, { Fragment } from "react"

import MedicinesCard from "../Cart/MedicinesCard"
import DocumentsCard from "../Cart/DocumentsCard"

const Cart = ({ open }) => {
  return (
    <div className="mt-2">
      <DocumentsCard open={open?.documentsCard} />
      <MedicinesCard open={open?.medicinesCard} />
    </div>
  )
}

export default Cart
