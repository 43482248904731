import React, { useContext } from "react"

import Card from "../Elements/Card/Card"
import DocumentsCardRow from "./DocumentsCard/DocumentsCardRow"

import { AppContext } from "../../context/AppContext"
import CollapsibleCard from "../Elements/CollapsibleCard"

const DocumentsCard = ({ open, isFullSize, location }) => {
  const { dispatch, state } = useContext(AppContext)
  const { flexmed } = state
  const { documents, beneficiaryDocuments } = flexmed

  let documentType =
    location?.pathname == "/flexmed/upload"
      ? { name: "Prescriptions", files: documents }
      : { name: "Documents", files: beneficiaryDocuments }

  const handleDeleteDocument = index => {
    let documentsList = [...documentType.files]
    documentsList.splice(index, 1)
    if (location?.pathname == "/flexmed/mechanics") {
      dispatch({ type: "SAVE_BENEFICIARY_DOCUMENTS", payload: documentsList })
    } else {
      dispatch({ type: "SAVE_DOCUMENTS", payload: documentsList })
    }
  }

  return (
    <CollapsibleCard
      title={`${documentType?.name} Uploaded (${documentType?.files?.length})`}
      color="white"
      errorMessage={
        documentType?.files === 0 &&
        "No documents uploaded.<br/>Please upload required documents."
      }
      isOpen={open}
    >
      <div className="mt-1">
        {documentType?.files.map((file, index) => (
          <DocumentsCardRow
            fileName={file.name}
            oldFileName={file.oldname}
            index={index}
            handleDeleteDocument={handleDeleteDocument}
            file={file}
            isFullSize={isFullSize}
          />
        ))}
      </div>
    </CollapsibleCard>
    // <Card
    //   header={`Documents Uploaded (${documents.length})`}
    //   errorMessage={
    //     documents.length === 0 &&
    //     "No documents uploaded.<br/>Please upload required documents."
    //   }
    // >
    //   <div className="card-content">

    //   </div>
    // </Card>
  )
}

export default DocumentsCard
