import Resizer from "react-image-file-resizer"
import moment from "moment"
import {
  checkIfHasRxRequired,
  accumulatePrice,
} from "../../Epharmacy/services/computations"

const MINIMUM_SIZE_TO_COMPRESS = 1048576

const generateFileName = (path, prefix) => {
  return `${prefix || "doc"}_${moment().format("YYYYMMDD_HHmmss")}`
}

export const isPdf = imgData => {
  return (
    imgData.substring("data:".length, imgData.indexOf(";base64")) ===
    "application/pdf"
  )
}

export const checkIfUploadDisabled = (flow, state) => {
  const hasNoDocuments = state.flexmed.documents.length === 0
  switch (flow) {
    case "epharmacy":
      const MAX_ORDER_TOTAL_LIMIT = 1000
      return (
        hasNoDocuments &&
        (checkIfHasRxRequired(state[flow].medicines) ||
          parseFloat(accumulatePrice(state[flow].medicines)) >=
            MAX_ORDER_TOTAL_LIMIT)
      )
    case "flexmed":
      return hasNoDocuments

    default:
      return !state.orderingFor
  }
}

export const isAlreadyUploaded = (file, filesUploaded) => {
  return filesUploaded.some(
    uploaded => file.path === uploaded.path || file.oldname === uploaded.oldname
  )
}

export const uploadDocument = (
  file,
  filesUploaded,
  setFilesUploaded,
  callback,
  alreadyUploadedCallback,
  prefix,
  allFilesUploaded
) => {
  let reader = new FileReader()
  const checkFilesUploaded = allFilesUploaded ? allFilesUploaded : filesUploaded
  if (file.type === "application/pdf" || file.size < MINIMUM_SIZE_TO_COMPRESS) {
    reader.readAsDataURL(file)
    reader.onload = () => {
      const newFile = {
        path: reader.result,
        name: generateFileName(file.type.split("/")[1], prefix),
        filename: generateFileName(file.type.split("/")[1], prefix),
        oldname: file.name,
        size: file.size,
      }
      if (
        isAlreadyUploaded(newFile, checkFilesUploaded) &&
        alreadyUploadedCallback
      )
        alreadyUploadedCallback(newFile)
      else
        setFilesUploaded(fileList => {
          let previousFilesUploaded = [...fileList]
          previousFilesUploaded.push(newFile)
          if (callback) callback(previousFilesUploaded)
          return previousFilesUploaded
        })
    }
  } else
    Resizer.imageFileResizer(
      file,
      1000,
      1000,
      "JPEG",
      50,
      0,
      uri => {
        const newFile = {
          path: uri,
          name: generateFileName(file.type.split("/")[1], prefix),
          filename: generateFileName(file.type.split("/")[1], prefix),
          oldname: file.name,
          size: file.size,
        }
        if (
          isAlreadyUploaded(newFile, filesUploaded) &&
          alreadyUploadedCallback
        ) {
          alreadyUploadedCallback(newFile)
        } else
          setFilesUploaded(fileList => {
            let previousFilesUploaded = [...fileList]
            previousFilesUploaded.push(newFile)
            if (callback) callback(previousFilesUploaded)
            return previousFilesUploaded
          })
      },
      "base64"
    )
}

export const hasFilesUploaded = files => {
  if (files?.length > 0) return true
  else return false
}

export const hasUploadedRequiredFiles = (files, prefix) => {
  const requiredDocuments = files?.filter(file =>
    file?.filename?.includes(prefix)
  )
  return requiredDocuments.length > 0
}

//https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export const generateFileSize = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes"

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}
