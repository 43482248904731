import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import classNames from "classnames"

import DropzoneBody from "./Dropzone/DropzoneBody"

import styles from "./utils/upload.module.scss"

const Dropzone = ({
  fileInputRef,
  filesUploaded = [],
  handleFileChooser,
  handleFileRead,
  notifications = [],
  rxUpload,
}) => {
  const data = useStaticQuery(graphql`
    {
      fileUploadSparkle: file(relativePath: { eq: "dropzone__upload.png" }) {
        childImageSharp {
          fixed(width: 125) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const fileUploadSparkle = data.fileUploadSparkle.childImageSharp.fixed

  return (
    <div className={classNames("container mt-1", styles["dropzone"])}>
      <input
        type="file"
        className={styles["dropzone__input"]}
        ref={fileInputRef}
        onChange={handleFileRead}
        multiple
        accept="image/png,image/jpeg,image/jpg,.pdf"
        value=""
      />

      {notifications.length > 0 && notifications.map(item => item)}

      <DropzoneBody
        filesUploaded={filesUploaded}
        handleFileChooser={handleFileChooser}
        fileUploadSparkle={fileUploadSparkle}
        rxUpload={rxUpload}
      />
    </div>
  )
}

export default Dropzone
