import React from "react"

const productsRequiringPrescriptions = [
  "Prescription drugs and vaccines",
  "Eyewear (e.g. reading glasses)",
  "Laboratory tests",
]

const EncodeOnlinePurchaseDocuments = () => (
  <div className="notification is-light mt-1">
    <div className="content">
      Prescriptions are required for the following:
      <ul className="mt-1">
        {productsRequiringPrescriptions.map(item => (
          <li>{item}</li>
        ))}
      </ul>
    </div>
  </div>
)

export default EncodeOnlinePurchaseDocuments
