import React, { useRef, useState, useEffect, useContext } from "react"
import { Formik, Form } from "formik"

import Section from "../Elements/Section"
import Dropzone from "./Dropzone"
import RequiredPrescriptions from "./RequiredPrescriptions"
import FormInput from "../Elements/Form/FormInput"
import UploadErrorNotification from "./Dropzone/UploadErrorNotification"
import CartLayout from "../Layout/CartLayout"
import EpharmacyCart from "../Epharmacy/Cart"
import FlexMedCart from "../FlexMed/Cart"
import { AppContext } from "../../context/AppContext"
import { isIeOrEdge } from "../../services/browserCompatibility"
import {
  uploadDocument,
  checkIfUploadDisabled,
} from "./services/uploadDocuments"
import { uploadPresciptionValidationSchema } from "./utils/formData"
import { navigate } from "gatsby"

const MAX_FILE_SIZE_IN_BYTES = 5242880
const MAX_FILE_COUNT = 6

const Upload = ({ backRoute, nextRoute, flow, location }) => {
  const fileInputRef = useRef(null)
  const [notifications, setNotifications] = useState([])
  const { dispatch, state } = useContext(AppContext)
  const [filesUploaded, setFilesUploaded] = useState(state.flexmed.documents)
  const allFilesUploaded = [
    ...state.flexmed.beneficiaryDocuments,
    ...state.flexmed.documents,
  ]
  const Cart =
    flow === "epharmacy" ? (
      <EpharmacyCart open={{ documentsCard: true }} />
    ) : (
      <FlexMedCart open={{ documentsCard: true }} location={location} />
    )

  const handleFileChooser = () => {
    fileInputRef.current.click()
  }

  const openFileChooser = () => {
    if (isIeOrEdge()) setTimeout(handleFileChooser, 0)
    else handleFileChooser()
  }

  const handleAlreadyUploaded = setNotifications => file => {
    setNotifications(notificationsList => {
      let previousNotifications = [...notificationsList]
      previousNotifications.push(
        <UploadErrorNotification
          fileName={file.oldname}
          message="has already been uploaded."
        />
      )
      return previousNotifications
    })
  }

  const closeNotifications = () => {
    setNotifications([])
  }

  const handleFileRead = event => {
    closeNotifications()
    const tempFilesUploaded = [...event.target.files]
    const initialNotifications = []
    if (tempFilesUploaded.length + filesUploaded.length > MAX_FILE_COUNT)
      initialNotifications.push(
        <UploadErrorNotification
          message={`Please upload only a maximum of ${MAX_FILE_COUNT} files.`}
        />
      )
    else
      for (let i = 0; i < tempFilesUploaded.length; i++) {
        if (tempFilesUploaded[i].size < MAX_FILE_SIZE_IN_BYTES)
          uploadDocument(
            tempFilesUploaded[i],
            filesUploaded,
            setFilesUploaded,
            prevFilesUploaded => {
              dispatch({
                type: "SAVE_DOCUMENTS",
                payload: [...prevFilesUploaded],
              })
            },
            handleAlreadyUploaded(setNotifications),
            "rx",
            allFilesUploaded
          )
        else
          initialNotifications.push(
            <UploadErrorNotification
              fileName={tempFilesUploaded[i].name}
              message="is greater than 5MB. Please upload a file or photo less than 5MB."
            />
          )
      }
    setNotifications(initialNotifications)
  }

  // const displayNavigationModal = () => {
  //   dispatch({
  //     type: "SHOW_MODAL",
  //     payload: {
  //       heading: "Cancel your request",
  //       isCard: true,
  //       headerClass: "is-size-5",
  //       content: <ConfirmNavigationModal backRoute={backRoute} />,
  //     },
  //   })
  // }
  const handleOnSubmit = async values => {
    await dispatch({
      type: "SAVE_PRESCRIPTION_FOR",
      payload: values.prescriptionFor,
    })
    let prevPath = location.pathname
    navigate("/flexmed/encode", { state: { prevPath } })
  }

  useEffect(() => {
    if (state.flexmed.documents.length < filesUploaded.length)
      setFilesUploaded(state.flexmed.documents)
  }, [state])

  return (
    <Formik
      initialValues={{
        prescriptionFor: state?.flexmed?.prescriptionFor,
      }}
      onSubmit={handleOnSubmit}
      validationSchema={uploadPresciptionValidationSchema}
    >
      {({ values }) => (
        <Form>
          <CartLayout
            isCentered
            desktop={8}
            fullhd={8}
            submit={{
              label: "Next",
              link: nextRoute,
              disabled:
                !state.flexmed.orderingFor ||
                (checkIfUploadDisabled(flow, state) &&
                  state.flexmed.benefitType === "Optical"),
            }}
            back={{
              label: "Back",
              link: location?.state?.prevPath || backRoute,
              // callback: flow === "flexmed" && displayNavigationModal,
            }}
            cartContainer={Cart}
            location={location}
          >
            <Section title="Upload Prescription">
              <Dropzone
                fileInputRef={fileInputRef}
                handleFileChooser={openFileChooser}
                filesUploaded={filesUploaded}
                handleFileRead={handleFileRead}
                notifications={notifications}
                rxUpload={true}
              />
              {state.flexmed.documents.length === 0 && (
                <p className="help has-text-danger">
                  Please upload your prescription.
                </p>
              )}
            </Section>
            <RequiredPrescriptions />
            <Section title="Input Prescription Details">
              <FormInput
                name="prescriptionFor"
                value={values.prescriptionFor}
                label="Name on Prescription"
                placeholder="Juan Dela Cruz"
                helper="Please fill this up for faster processing"
                isRequired
              />{" "}
            </Section>
          </CartLayout>
        </Form>
      )}
    </Formik>
  )
}

export default Upload
