import React from "react"
import classNames from "classnames"
// import Img from "gatsby-image"

import styles from "../utils/upload.module.scss"

const UploadBox = ({ fileCount, uploadIcon, rxUpload }) => {
  if (rxUpload) {
    return (
      <p
        className={classNames(
          "title my-1 is-size-4 has-text-primary has-text-centered",
          styles["dropzone__uploadBox"]
        )}
      >
        {fileCount ? "+ Add another prescription" : "Add prescription upload"}
      </p>
    )
  } else {
    return (
      <p
        className={classNames(
          "title my-1 is-size-4 has-text-primary has-text-centered",
          styles["dropzone__uploadBox"]
        )}
      >
        {fileCount
          ? "+ Add another photo or file"
          : "Take a photo or choose a file"}
      </p>
    )
  }
  // } else {
  //   return (
  //     <div className={classNames("is-flex", innerBorder)}>
  //       <Img fixed={uploadIcon} alt="fileUpload" />
  //       <div className="mx-2">
  //         <p className="title is-size-4 has-text-primary has-text-centered mb-2">
  //           Take a photo or choose a file
  //           <p className="help has-text-primary">
  //             Maximum file size limit of 2MB,{" "}
  //             <br className="is-hidden-tablet" />
  //             upload up to 4 files
  //           </p>
  //         </p>
  //       </div>
  //     </div>
  //   )
  // }
}

const DropzoneBody = ({
  filesUploaded,
  handleFileChooser,
  fileUploadSparkle,
  rxUpload,
}) => (
  // eslint-disable-next-line
  <div
    className={classNames("is-flex mb-1", styles["dropzone__container"])}
    onClick={handleFileChooser}
  >
    <UploadBox
      fileCount={filesUploaded.length}
      uploadIcon={fileUploadSparkle}
      rxUpload={rxUpload}
    />
  </div>
)

export default DropzoneBody
