import React from "react"
import classNames from "classnames"

import Container from "./Container"
import ActionButtons from "../Elements/ActionButtons"

const CartLayout = props => (
  <Container {...props}>
    {props.cartContainer ? (
      <div className="columns">
        <div
          className={classNames("column pb-0-mobile", {
            "is-7": props.cartContainer,
          })}
        >
          {props.children}
        </div>
        <div className="column">{props.cartContainer}</div>
      </div>
    ) : (
      props.children
    )}
    {props.promptMessage ? props.promptMessage : null}
    <ActionButtons {...props} />
  </Container>
)

export default CartLayout
