import React from "react"
import DocumentsCard from "../Cart/DocumentsCard"
import ReceiptsCard from "../Cart/ReceiptsCard"

export const EncodeReceiptCart = ({ open, documents, setDocuments }) => (
  <ReceiptsCard
    open={open}
    documents={documents}
    isFullSize
    setDocuments={setDocuments}
  />
)

const Cart = ({ open, baseRoute, location, isFullSize }) => (
  <DocumentsCard
    open={open?.documentsCard}
    baseRoute={baseRoute}
    isFullSize
    location={location}
    isFullSize={isFullSize}
  />
)

export default Cart
