import React from "react"

import DocumentsCardRow from "./DocumentsCard/DocumentsCardRow"
import CollapsibleCard from "../Elements/CollapsibleCard"
import { uploadGuidelines } from "./utils/uploadGuidelines"

const ReceiptsCard = ({ open, isFullSize, documents, setDocuments }) => {
  const handleDeleteDocument = index => {
    const documentsList = [...documents]
    documentsList.splice(index, 1)
    setDocuments(documentsList)
  }

  return (
    <CollapsibleCard
      title={`Receipt Preview (${documents?.length})`}
      color="white"
      errorMessage={
        documents.length === 0 && {
          message: uploadGuidelines,
        }
      }
      isOpen={open}
    >
      <div className="mt-1">
        {documents.map((file, index) => (
          <DocumentsCardRow
            fileName={file.name}
            oldFileName={file.oldname}
            index={index}
            handleDeleteDocument={handleDeleteDocument}
            file={file}
            isFullSize={isFullSize}
          />
        ))}
      </div>
    </CollapsibleCard>
  )
}

export default ReceiptsCard
