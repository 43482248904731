import React from "react"
import Upload from "../../components/Upload/Upload"
import Layout from "../../components/Layout/Layout"

export default () => (
  <Layout
    title="Upload Documents"
    subtitle="Upload required documents to order"
    process="epharmacy"
    seoTitle="Upload Documents"
  >
    <Upload
      flow="epharmacy"
      backRoute="/epharmacy/order"
      nextRoute="/epharmacy/delivery-details"
    />
  </Layout>
)
