import React from "react"
import classNames from "classnames"
import styles from "./card.module.scss"

const Card = ({ children, header, errorMessage }) => (
  <div className={classNames("card mt-2", styles["card"])}>
    <div
      className={classNames(
        styles["card__header"],
        "has-background-light has-text-centered"
      )}
    >
      <p className="card-content title has-text-primary is-size-6">{header}</p>
    </div>
    <div>
      {errorMessage ? (
        <div
          className="mt-2 mb-4 has-text-grey has-text-centered"
          dangerouslySetInnerHTML={{ __html: errorMessage }}
        />
      ) : (
        children
      )}
    </div>
  </div>
)

export default Card
