import React, { Fragment } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./utils/elements.module.scss"

/**
 * @param {string} title string
 * @param {} children
 */

const Section = ({ title, children, addOns }) => (
  <Fragment>
    <div className={classNames(styles["section"])}>
      {addOns?.left}
      <h4 className="has-text-primary mt-0">{title}</h4>
      {addOns?.right}
    </div>
    <div className="mt-1 mb-3">{children}</div>
  </Fragment>
)

Section.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
}

export default Section
